

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; 
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import About from './Components/AboutUs/About';
import Contact from './Components/Contact/Contact';
import Pricing from './Components/Pricing/Pricing';
import CardList from './Components/Theams/CardList';
import FormPage from './Components/Theams/FormPage';
import LoginForm from './Components/Authentication/Login/Login.js';
import SignUpForm from './Components/Authentication/Signup/Signup.js';
import ForgotPasswordForm from './Components/Authentication/ForgottenPassword/ForgotPasswordForm.js';
import OtpVerificationForm from './Components/Authentication/OtpForm/Otp.js';
import axios from 'axios';
import Customer from './Components/BookTheCard/Customer.js';
import CardHome from './Components/Theams/FormComponent.js';
import PasswordResetForm from './Components/Authentication/PasswordResetFrom/PasswordResetFrom.js';
import FormComponent from './Components/Theams/FormComponent.js';
import CardComponent from './Components/Theams/CardComponent.js';
import ProtectedRoute from './Components/Authentication/ProtectedRoute/ProtectedRoute.js';
import ConditionalRoute from './Components/Theams/ConditionalRoute.js';

import TermsConditions from './Components/Footer/TermsConditions.js';
import PrivacyPolicy from './Components/Footer/PrivacyPolicy.js';
import CancellationRefundPolicy from './Components/Footer/CancellationRefundPolicy.js';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [contacts, setContacts] = useState([]);
const [websiteUrl,setWebsiteUrl] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);  // Set isLoggedIn based on whether token exists
  }, []);
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    const firstLogin = localStorage.getItem('firstLogin');

    setIsLoggedIn(!!token);
    setIsFirstLogin(firstLogin === null); // If firstLogin is null, it’s the user’s first login
  }, []);

  // const handleLogin = (token) => {
  //   localStorage.setItem('token', token);
  //   setIsLoggedIn(true);
  // };
  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    localStorage.setItem('firstLogin', 'false'); // Mark that the user has logged in before
    setIsLoggedIn(true);
    setIsFirstLogin(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };
 

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get('https://api.nfcdesignstudio.com/API/v1/api/getform');
        setContacts(response.data); 
        console.log('Fetched contact data:', response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error('Error: Endpoint not found (404)');
        } else {
          console.error('Error fetching contact data:', error);
        }
        // Handle error appropriately, e.g., setContacts([]) or display an error message
      }
    };
  
    fetchContactData();
  }, []);

  


  return (
    <BrowserRouter >
      <div>
      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/cardlist" element={<CardList onSelectCard={() => {}} />} />
          <Route path="/form/:id" element={<FormPage  />} />
          {/* <Route path="/result" element={<ResultPage />} /> */}
          <Route path="/login"  element= {<LoginForm/>}/>
          <Route path="/signup" element={<SignUpForm/>}/>
          <Route path="/forgot-password" element={<ForgotPasswordForm />} />
          <Route path="/otp-form" element={<OtpVerificationForm />} />
          <Route path="/reset-password" element={<PasswordResetForm />} />
          <Route path="/reset-password/:token" element={<PasswordResetForm />} />
          <Route path="/form" element={<FormPage />} />
           {/* <Route
        path="/login"
        element={isLoggedIn ? <Navigate to="/formComponent" /> : <LoginForm onLogin={handleLogin} />}
      /> */}
    
    
  
     {/* <Route path='/login' element={<LoginForm/>}/> */}
      {/* <Route path='/customer' element={<ProtectedRoute element={<Customer/>}/> }/> */}
      <Route path="/formComponent"  element={<ProtectedRoute element={<FormComponent />} /> }/>
      <Route path="/termsConditions"   element={<TermsConditions />} /> 
      <Route path="/privacyPolicy"   element={<PrivacyPolicy />} /> 
   <Route path="/cancellationRefundPolicy"   element={<CancellationRefundPolicy />} /> 
      <Route path='/:websiteUrl' element={<CardComponent />} />
         {/* <Route path='/:websiteUrl'  element={<CardComponent/>}/> */}
         
         
      
          
        </Routes>
       
      </div>
    </BrowserRouter>
  );
}

export default App;
