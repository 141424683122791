// import React from 'react'
// import Footer from '../Footer/Footer';
// import Header from '../Header/Header';
// const About = () => {
//   return (
//     <>
 
//     <Header/>

//     <div>About</div>
//     <Footer />
//     </>
//   )
// }

// export default About





import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FaShippingFast } from 'react-icons/fa'; // Import shipping icon


import Footer from '../Footer/Footer';
import Header from '../Header/Header';
const About = () => {
  // State for billing and shipping information
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  
  const handlePaymentSubmit = (e) => {
    e.preventDefault();
    
    // Validation check for card details
    if (!cardNumber || !expiryDate || !cvv || !name) {
      setError('All fields are required');
      return;
    }
    
    // Simulate payment processing (You should integrate with a payment gateway here)
    console.log('Processing payment...');
    console.log({ cardNumber, expiryDate, cvv, name });
    setError('');
    
    // Redirect or show success/failure based on payment gateway response
  };


  return (
    <>
     <Header/>
    <div>
    
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <h3 className="text-center">Payment Form</h3>
          {error && <div className="alert alert-danger">{error}</div>}
          <Form onSubmit={handlePaymentSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name on Card</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your name" 
                value={name}
                onChange={(e) => setName(e.target.value)} 
              />
            </Form.Group>

            <Form.Group controlId="formCardNumber">
              <Form.Label>Card Number</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter card number" 
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)} 
                maxLength="16"
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group controlId="formExpiryDate">
                  <Form.Label>Expiry Date</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="MM/YY" 
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)} 
                    maxLength="5"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formCvv">
                  <Form.Label>CVV</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="CVV" 
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value)} 
                    maxLength="3"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" type="submit" className="w-100 mt-3">
              Pay Now
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
      
    </div>
    <Footer />
    </>
  );
};

export default About;
