// import React from 'react'
// import Footer from '../Footer/Footer';
// import Header from '../Header/Header';
// const Contact = () => {
//   return (
//     <>
 
//     <Header/>
//     <div> info@emedha.com
// +91 9849164361
// Unit # 208, 2nd Floor, Ashoka Bhupal Chambers, S.P.Road, Secunderabad - 500 003, Telangana, INDIA</div>
//     <Footer />
//     </>
//   )
// }

// export default Contact


import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'; // Importing required icons
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Contact = () => {
  return (
    <>
      <Header />

      <div className="container mt-5">
        <h2 className="text-center">Contact Us</h2>

        <div className="row justify-content-center mt-4">
          {/* Contact Info */}
          <div className="col-lg-6 col-md-8 col-sm-12 text-center">
            <div className="card shadow-sm">
              <div className="card-body">
                {/* <h5 className="card-title">Get in Touch</h5> */}
                <p className="card-text">
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <FaEnvelope size={24} style={{margin:'10px'}} />
                    <span>info@emedha.com</span>
                  </div>

                 <div className="d-flex justify-content-center align-items-center mb-3">
                 <a href="tel:+919849164361" style={{color:'black'}} className="text-decoration-none d-flex align-items-center">
                   <FaPhone size={24} style={{ margin: '5px' }} />
                   <span >+91 9849164361</span>
                 </a>
               </div>
               

                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <FaMapMarkerAlt size={34} />
                    <span>Unit # 208, 2nd Floor, Ashoka Bhupal Chambers,<br></br> S.P. Road, Secunderabad - 500 003, Telangana, INDIA</span>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
<br></br>
      <Footer />
    </>
  );
};

export default Contact;
