
// import React, { useRef, useEffect, useState } from 'react';
// import QRCode from 'qrcode';
// import { LiaQrcodeSolid } from "react-icons/lia";
// import { Col, Row } from 'react-bootstrap';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';

// const QRCodeWithLogo = ({ websiteurl, qrCodeSize, logoSize }) => {
//   const canvasRef = useRef(null);
//   const [dataURL, setDataURL] = useState('');
//   const [images, setImages] = useState([]);
//   const { websiteUrl } = useParams();

//   useEffect(() => {
//     const fetchWebsiteData = async () => {
//       try {
//         const response = await axios.get(`https://api.nfcdesignstudio.com/API/v1/${websiteUrl}`);
//         console.log('Fetched website data:', response.data); // Log the entire response

//         // Assuming response.data is an array of card objects
//         if (Array.isArray(response.data)) {
//           const fetchedImages = response.data.map(card => card.image).filter(Boolean); // Adjust this if necessary
//           console.log('Extracted images:', fetchedImages); // Log the extracted images
//           setImages(fetchedImages);
//         } else {
//           console.error('Response data is not an array');
//         }
//       } catch (error) {
//         console.error('Error fetching website data:', error);
//       }
//     };

//     fetchWebsiteData();
//   }, [websiteUrl]);

//   useEffect(() => {
//     QRCode.toCanvas(
//       canvasRef.current,
//       websiteurl,
//       {
//         width: qrCodeSize,
//         margin: 1,
//         color: {
//           dark: '#000000',
//           light: '#ffffff'
//         },
//         errorCorrectionLevel: 'H'
//       },
//       (err) => {
//         if (err) {
//           console.error('Error generating QR Code:', err);
//           return;
//         }

//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');

//         // Check if images exist and use the first one
//         if (images.length > 0) {
//           const logoSrc = images[0]; // Use the first image from the fetched data
//           console.log('Using logo source:', logoSrc); // Log the logo source
          
//           const logo = new Image();
//           logo.crossOrigin = 'anonymous'; // Allow cross-origin requests
//           logo.src = logoSrc;

//           logo.onload = () => {
//             const logoX = (qrCodeSize - logoSize) / 2;
//             const logoY = (qrCodeSize - logoSize) / 2;
//             ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);
//             setDataURL(canvas.toDataURL('image/png')); // Set Data URL for download
//           };

//           logo.onerror = () => {
//             console.error('Error loading logo image:', logoSrc);
//           };
//         } else {
//           console.log('No images found');
//           setDataURL(canvas.toDataURL('image/png')); // Set Data URL without logo
//         }
//       }
//     );
//   }, [websiteurl, images, qrCodeSize, logoSize]);

//   const handleDownload = () => {
//     if (dataURL) {
//       const link = document.createElement('a');
//       link.href = dataURL;
//       link.download = 'qrcode.png';
//       link.click();
//     }
//   };

//   return (
//     <>
//       <Row>
//         <Col xs={12} md={8} className="text-center">
//           <canvas
//             ref={canvasRef}
//             width={qrCodeSize}
//             height={qrCodeSize}
//             style={{ border: '1px solid #000000' }}
//           />
//         </Col>
//         <Col xs={12} md={4} className="text-center">
//           <div className='m-5'>
//             <button className="btn btn-outline-success" onClick={handleDownload}>
//               Download <LiaQrcodeSolid style={{ fontSize: '40px' }} />
//             </button>
//           </div>
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default QRCodeWithLogo;





import React, { useRef, useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { LiaQrcodeSolid } from "react-icons/lia";
import { Col, Row } from 'react-bootstrap';
const QRCodeWithLogo = ({ websiteurl, qrCodeSize, eMedhaLogo, logoSize }) => {
  const canvasRef = useRef(null);
  const [dataURL, setDataURL] = useState('');

  useEffect(() => {
    // Generate QR code using qrcode library
    QRCode.toCanvas(
      canvasRef.current,
      websiteurl,
      {
        width: qrCodeSize,
        margin: 1, // Adjust margin if necessary
        color: {
          dark: '#000000',  // QR code color
          light: '#ffffff'  // Background color
        },
        errorCorrectionLevel: 'H' // High error correction
      },
      (err) => {
        if (err) {
          console.error('Error generating QR Code:', err);
          return;
        }

        // Draw logo on top
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (eMedhaLogo) {
          const logo = new Image();
          logo.src = eMedhaLogo;
          logo.onload = () => {
            const logoX = (qrCodeSize - logoSize) / 2;
            const logoY = (qrCodeSize - logoSize) / 2;
            ctx.drawImage(logo, logoX, logoY, logoSize, logoSize);

            // Set Data URL for download
            setDataURL(canvas.toDataURL('image/png'));
          };
        } else {
          // No logo, just set the Data URL
          setDataURL(canvas.toDataURL('image/png'));
        }
      }
    );
  }, [websiteurl, eMedhaLogo, qrCodeSize, logoSize]);

  const handleDownload = () => {
    if (dataURL) {
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'qrcode.png';
      link.click();
    }
  };

  return (
    <>
   <Row >
   <Col xs={12} md={8} className="text-center">
      <canvas
        ref={canvasRef}
        width={qrCodeSize}
        height={qrCodeSize}
        style={{ border: '1px solid #000000' }}
      />
      </Col>
      <Col xs={12} md={4} className="text-center    ">
      <div className='m-5'>
      <button className="btn btn-outline-success" onClick={handleDownload}>Download <LiaQrcodeSolid style={{fontSize:'40px'}}/></button>
    </div>
    </Col>
    </Row>
    </>
  );
};

export default QRCodeWithLogo;
