import React from 'react';
import Footer from './Footer';
import Header from '../Header/Header';
import { Container } from 'react-bootstrap';


const CancellationRefundPolicy = () => {
  return (
    <>
    <Header/>
    <Container className="my-5">
    <div>
      <h2>Cancellation & Refund Policy</h2>
      <p>
        Thank you for choosing our digital visiting card services. We strive to provide quality products and services. Please review our cancellation and refund policy below:
      </p>

      <h2>Cancellation Policy</h2>
      <ul>
        <li>
          <strong>Order Cancellation:</strong> You may cancel your order within 24 hours of purchase. To cancel, please contact us at [info@emedha.com/9849164361]. After 24 hours, your order will be processed and cannot be canceled.
        </li>
      </ul>

      <h2>Refund Policy</h2>
      <ul>
        <li>
          <strong>Refund Eligibility:</strong> Refunds will only be issued if the cancellation request is made within 24 hours of purchase.
        </li>
        <li>
          <strong>Refund Process:</strong> If you are eligible for a refund, we will process it within 7 business days. The refund will be issued to the original payment method used at the time of purchase.
        </li>
        <li>
          <strong>Non-Refundable Services:</strong> Please note that fees for services rendered, including any verifications, are non-refundable once the service has been completed.
        </li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For any questions regarding our Cancellation & Refund Policy, please reach out to us at [info@emedha.com/9849164361].
      </p>

      <p>Thank you for your understanding!</p>
    </div>
    </Container>
    <Footer/></>
  );
};

export default CancellationRefundPolicy;
