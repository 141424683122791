import React, { useState } from 'react'
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
 import './Pricing.css';
import CardList from '../Theams/CardList';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Pricing = () => {
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const prices = [
    { type: 'One Card', price: 2000 },
    { type: 'Five Cards', price: '1500 / each' },
  ];

  const handleCardClick = (price) => {
    setSelectedPrice(price);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedPrice(null);
  };

  // // Inline styles for various components
  // const pricingTableStyle = {
  //   // textAlign: 'center',
  //   // fontFamily: 'Arial, sans-serif',
  //   // margin: '20px',
  // };

  // const cardsContainerStyle = {
  //   // display: 'flex',
  //   // justifyContent: 'center',
  //   // gap: '20px',
  //   // marginTop: '20px',
  // };

  // const pricingCardStyle = {
  //   // backgroundColor: '#f8f8f8',
  //   // borderRadius: '8px',
  //   // padding: '20px',
  //   // width: '200px',
  //   // cursor: 'pointer',
  //   // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  //   // transition: 'transform 0.3s ease',
  // };

  // const modalOverlayStyle = {
  //   // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  //   // display: 'flex',
  //   // justifyContent: 'center',
  //   // alignItems: 'center',
  //   // position: 'fixed',
  //   // top: '0',
  //   // left: '0',
  //   // right: '0',
  //   // bottom: '0',
  //   // zIndex: 999,
  // };

  // const modalStyle = {
  //   // backgroundColor: 'white',
  //   // padding: '30px',
  //   // borderRadius: '8px',
  //   // width: '400px',
  //   // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  //   // overflowY: 'auto', // Allow scrolling if content overflows
  // };

  // const modalHeadingStyle = {
  //   // marginTop: '0',
  //   // fontSize: '24px',
  //   // color: '#333',
  //   // marginBottom: '10px', // Add space below the heading
  // };

  // const modalTextStyle = {
  //   // fontSize: '18px',
  //   // marginBottom: '20px', // Add space after paragraphs
  // };

  // const modalListStyle = {
  //   // fontSize: '16px',
  //   // color: '#555',
  //   // margin: '10px 0',
  // };

  // const closeButtonStyle = {
  //   // marginTop: '20px',
  //   // padding: '10px 20px',
  //   // backgroundColor: '#007bff',
  //   // color: 'white',
  //   // border: 'none',
  //   // borderRadius: '5px',
  //   // cursor: 'pointer',
  // };

  // const closeButtonHoverStyle = {
  //   // backgroundColor: '#0056b3',
  // };

  return (
    <>

      <Header />

      <h1 className="mb-4 text-center">Sample Card's</h1>
      <CardList />





      <div className="pricing-table">
      
      <h2>Pricing Information</h2>
      <table>
        <thead>
          <tr>
            <th>Card Type</th>
            <th>Price (INR)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>One Card</td>
            <td>2000</td>
          </tr>
          <tr>
            <td>Five Cards</td>
            <td>1500 / each</td>
          </tr>
        </tbody>
      </table>
    </div>


     {/* <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', margin: '20px' }}>
      <h2>Pricing Information</h2>
      
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
        {prices.map((price, index) => (
          <div
            key={index}
            onClick={() => handleCardClick(price)}
            style={{
              backgroundColor: '#f8f8f8',
              borderRadius: '8px',
              padding: '20px',
              width: '200px',
              cursor: 'pointer',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            <h3>{price.type}</h3>
            <p>{price.price}</p>
          </div>
        ))}
      </div>

      
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Payment Information"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        {selectedPrice ? (
          <>
            <h2>Selected Price</h2>
            <p>{selectedPrice.type}: {selectedPrice.price}</p>
            <h3>Payment Procedure</h3>
            <br></br>
            <ol>
              <li>Choose your preferred payment method (Credit/Debit/Net Banking)</li>
              <li>Complete the payment and confirm your order.</li>
              <li>After confirmation, you will receive a payment receipt.</li>
            </ol>
            <button onClick={closeModal}>Close</button>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </Modal>
    </div> */}
      <Footer />
    </>
  )
}

export default Pricing