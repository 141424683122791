
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Card, Col, Row, Carousel } from 'react-bootstrap';
import './CardComponent.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGithub, FaTelegramPlane } from 'react-icons/fa';
import { IoLogoWhatsapp, IoMdCall } from "react-icons/io";
import { MdCall, MdVideoLibrary } from "react-icons/md";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { BsFillFileEarmarkPptFill } from "react-icons/bs";
import { IoMdPhotos } from "react-icons/io";
import { MdOutlineVideoSettings } from "react-icons/md";
import { IoEarth } from "react-icons/io5";
import { FcGallery } from "react-icons/fc";
import { ImLocation2 } from "react-icons/im";
import { BiLogoGmail } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import ModalComponent from './ModalComponent';
//import ModalComponent from  '../ModalComponent/ModalComponent';
import ModalComponents from '../ModalComponent/ModalComponents';
import CardList from './CardList';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import eMedhaLogo from './eMedhaLogo.png'
// import ModalComponent from '../ModalComponent/ModalComponent';
import QRCode from 'qrcode.react';
import QRCodeWithLogo from '../QRCode/QRCodeWithLogo';
import FormComponent from './FormComponent';
import EditCardModal from './EditCardModal';
import { FaEdit } from "react-icons/fa";
import bg from './bg.jpg';
import bg1 from './bg1.jpg';
import bg2 from './bg2.png';
import bg3 from './bg3.jpg';
import bgg from './bgg.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';


const CardContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
`;

const FormContainer = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
`;

const CardComponent = () => {
  // const [cards, setCards] = useState([]);

  const [showModal, setShowModal] = useState(false);
  //  const [selectedImage, setSelectedImage] = useState(null);

  const [showModall, setShowModall] = useState(false);
  const { websiteUrl } = useParams();
  const [websiteData, setWebsiteData] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [isEditButtonVisible, setIsEditButtonVisible] = useState(true);

  const [images1, setImages1] = useState([]);

  console.log(images1)

  const [cards, setCards] = useState([])
  const [selectedImage, setSelectedImage] = useState({
    image1: null, // Placeholder for image 1
    image2: null, // Placeholder for image 2


  });
  // const [websiteurl, setWebsiteurl] = useState(''); // Initialize with default or dynamic value

  useEffect(() => {
    const fetchWebsiteData = async () => {
      try {
        const response = await axios.get(`https://api.nfcdesignstudio.com/API/v1/${websiteUrl}`);
        //   const response = await axios.get(`https://api.nfcdesignstudio.com/API/v1/${websiteUrl}`);

        if (Array.isArray(response.data) && response.data.length > 0) {
          setCards(response.data); // Extract the first object from the array

          console.log(response.data.images)

        } else {
          setCards(response.data);
        }
        console.log('Fetched website data:', response.data);
        setImages1(response.data.images || []);
      } catch (error) {
        console.error('Error fetching website data:', error);
      }
    };

    fetchWebsiteData();
  }, [websiteUrl]);

  console.log(cards)


  const websiteurl = (`https://nfcdesignstudio.com/${websiteUrl}`);

  const qrCodeSize = 256; // Size of the QR code
  const logoSize = 80;    // Size of the logo/image
  const logoMargin = 20;  // Margin around the logo



  // useEffect(() => {
  //   const fetchContactData = async () => {
  //     try {
  //       const response = await axios.get('https://api.nfcdesignstudio.com/API/v1/get-form-data');
  //       setContacts(response.data); // Assuming response.data is an array of contacts
  //       console.log('Fetched contact data:', response.data);
  //     } catch (error) {
  //       if (error.response && error.response.status === 404) {
  //         console.error('Error: Endpoint not found (404)');
  //       } else {
  //         console.error('Error fetching contact data:', error);
  //       }
  //     }
  //   };

  //   fetchContactData();
  // }, []);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  // useEffect(() => {
  //   // Load form data from local storage
  //   const storedCards = JSON.parse(localStorage.getItem('forms')) || [];
  //   setCards(storedCards);
  // }, []);


  const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);


  const [selectedVideos, setSelectedVideos] = useState(null);

  const handleCloseModal = () => setShowModal(false);


  // const handleShowModall = () => setShowModall(true);
  const handleCloseModall = () => setShowModall(false);



  const handleShowModal = (image1, image2) => {
    setSelectedImage({ image1, image2 });
    setShowModal(true);
  };

  const handleOpenLocation = () => {
    if (cards && cards.location) {
      window.open(cards.location, '_blank');
    }
  };

  const handleOpenWhatsappChat = () => {
    if (cards && cards.whatsappChat) {
      window.open(cards.whatsappChat, '_blank');
    }

  };

  //27:08:2024 time 11:58
  const [showModal1, setShowModal1] = useState(false);
  const [cardData, setCardData] = useState({
    companyName: '',
    name: '',
    designation: '',
    phoneNumber: '',
    email: '',
    address: '',
    whatsappChat: '',
    location: '',
    call: '',
    website: '',
    videoUrl1: '',
    videoUrl2: '',
    facebookUrl: '',
    linkedinUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    telegramUrl: '',
    images: [], // This can be an array of image URLs or file objects
  });

  // const handleSave = (updatedData) => {
  //   // Handle saving the updated data
  //   console.log('Updated Data:', updatedData);
  //   setIsEditButtonVisible(false);
  //   setShowModal(false);
  // };



  const handleSave = async (updatedData) => {
    try {
      // Assuming an API endpoint exists to save the updated data
      await axios.post('http://api.nfcdesignstudio.com/API/v1/api/updateCardData', updatedData);
      setIsEditButtonVisible(false); // Hide the "Edit Card" button after saving changes
      setShowModal1(false);
    } catch (error) {
      console.error('Error saving card data:', error);
    }
  };


  const handleOpenModall = () => {
    setShowModall(true);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const [images, setImages] = useState([]);


  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleImageChange = (newImages) => {
  //   setImages(newImages);
  // };
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  //   const downloadVCard = () => {
  //     // Ensure cards is populated
  //     // if (!Array.isArray(cards) || cards.length === 0) {
  //     //   console.error('Invalid or empty cards list.');
  //     //   return;
  //     // }

  //     // Generate vCard data for each card and combine them
  //     const vCardData = cards.map(card => `
  // BEGIN:VCARD
  // VERSION:3.0
  // TITLE:${card.name || ''}
  // ORG:${card.companyName || ''}
  // TITLE:${card.designation || ''}
  // TEL:${card.phoneNumber || ''}
  // EMAIL:${card.email || ''}
  // ADR:${card.address || ''}
  // URL:${card.websiteUrl || ''}
  // END:VCARD
  // `.trim()).join('\n\n'); // Double newlines to separate vCards

  //     // Create a Blob from the vCard data
  //     const blob = new Blob([vCardData], { type: 'text/vcard' });
  //     const url = URL.createObjectURL(blob);

  //     // Create a link element to trigger the download
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'contacts.vcf'; // File name
  //     document.body.appendChild(a);
  //     a.click();

  //     // Clean up
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(url);
  //   };
  // const downloadVCard = () => {
  //   if (!Array.isArray(cards) || cards.length === 0) {
  //     console.error('No cards available for download.');
  //     return;
  //   }

  //   const vCardData = cards.map(card => `
  // BEGIN:VCARD
  // VERSION:3.0
  // FN:${card.name || ''}
  // ORG:${card.companyName || ''}
  // TITLE:${card.designation || ''}
  // TEL:${card.phoneNumber || ''}
  // EMAIL:${card.email || ''}
  // ADR:${card.address || ''}
  // URL:${card.websiteUrl || ''}
  // END:VCARD
  //   `.trim()).join('\n\n'); // Double newlines to separate vCards

  //   const blob = new Blob([vCardData], { type: 'text/vcard;charset=utf-8' });
  //   const url = URL.createObjectURL(blob);

  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'contacts.vcf'; // File name
  //   document.body.appendChild(a);
  //   a.click();

  //   document.body.removeChild(a);ORG
  //   URL.revokeObjectURL(url);
  // };
  const downloadVCard = () => {

    const vCardData = `BEGIN:VCARD
VERSION:3.0
FN:${cards.name || ''}
ORG:${cards.companyName || ''}
TITLE:${cards.designation || ''}
TEL:${cards.phoneNumber || ''}
EMAIL:${cards.email || ''}
ADR:${cards.address || ''}  
URL:${cards.websiteUrl || ''}
END:VCARD`;

    // Create a Blob from the vCard data
    const blob = new Blob([vCardData], { type: 'text/vcard' });

    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'contact.vcf';

    // Append to the body and trigger a click to download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };


  const handleOpenImageUploadModal = () => {
    setShowImageUploadModal(true);
  };

  const handleCloseImageUploadModal = () => {
    setShowImageUploadModal(false);
  };


  const handleEditCard = async (email) => {
    try {
      const response = await fetch(`https://api.nfcdesignstudio.com/API/v1/api/getCardData?email=${email}`);
      const data = await response.json();
      setCardData(data[0]);
      setShowModal1(true);
    } catch (error) {
      console.error('Error fetching card data:', error);
    }
  };

  const loginEmail = localStorage.getItem("loginEmail")


  const handleButtonClick = () => {
    setShowModal1(true);
    handleEditCard(loginEmail);
  };


  const handleFileOpen = (url) => {
    // Forcing download
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'; // Open in a new tab
    link.rel = 'noopener noreferrer'; // Security improvement
    link.download = ''; // Suggests downloading the file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };




  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageDimensions({ width: naturalWidth, height: naturalHeight });
  };

  const { width, height } = imageDimensions;

  // Check if the image is square, rectangular, or circular
  const isSquare = width === height;
  const isCircle = width === height && width > 100; // You can adjust the threshold for circle size




  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the device is mobile
    const checkMobile = () => setIsMobile(window.innerWidth <= 576);

    // Initial check on mount
    checkMobile();

    // Add event listener to handle resize
    window.addEventListener('resize', checkMobile);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <>
      {/* <CardList/> */}
      <CardContainer>


        {/* <h2>Cards</h2> */}

        <FormContainer style={{
          backgroundImage: `url(${bg3})`, // Replace with your image path
          backgroundSize: 'cover', // Cover the entire container
          backgroundPosition: 'center', // Center the background image


          padding: '20px'
        }}>
          <div>
            <Row>

              <div className='your-class mb-2'>
                {/* <Col md={6} style={{ position: 'relative', width: '100%', height: '250px' }}>
                  {/* {cards.image && (
                    <div
                      style={{
                        backgroundImage: `url(${cards.logo})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        borderRadius: '0%', // Softer corner
                      }}
                    />
                    
                  )}  
                    {cards.image && (
                    <img
                      variant="top"
                      src={cards.image}
                      alt="Card image"
                      style={{
                        width: '30%', // Use percentage for responsive sizing
                        height: 'auto', // Maintain aspect ratio
                        borderRadius: '100%', // Circle shape
                        position: 'absolute',
                        top: '80%',
                        left: '20%', // Center the image horizontally
                        transform: 'translate(-50%, -50%)', // Center the image vertically
                        zIndex: 2,
                      }}
                    />
                  )}
                </Col> */}

                {/* {images1.map((image, index) => (
                    <img
                    key={index}
                      style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        borderRadius: '0%', // Softer corner
                      }}
                    />

                  ))} */}

                {/* <Col md={6} style={{ position: 'relative', width: '100%', height: '400px' }}> 
                  <Carousel style={{
                    width: '100%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100%', // Set carousel height to fill parent container
                    position: 'absolute',
                  }}>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={cards.image}
                        alt="First slide"
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }} // Fixed width and height
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block "
                        src={images1[0]}
                        alt="Second slide"
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }} // Fixed width and height
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block "
                        src={images1[1]}
                        alt="Third slide"
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }} // Fixed width and height
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block "
                        src={images1[2]}
                        alt="Fourth slide"
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }} // Fixed width and height
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block "
                        src={images1[3]}
                        alt="Fifth slide"
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }} // Fixed width and height
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block "
                        src={images1[4]}
                        alt="Fifth slide"
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }} // Fixed width and height
                      />
                    </Carousel.Item>

                  </Carousel>

                  {cards.image && (
                    <img
                      variant="top"
                      src={cards.image}
                      alt="Card image"
                      onLoad={handleImageLoad}
                      style={{
                        // Dynamically adjust size based on image aspect ratio
                        width: '30%', // Use percentage for responsive sizing
                        height: 'auto', // Maintain aspect ratio
                        borderRadius: isCircle ? '50%' : '10px',  // Apply circle border radius only if square/circular
                        position: 'absolute',
                        top: '80%',
                        left: '20%',
                        transform: 'translate(-50%, -50%)', // Perfect centering
                        zIndex: 2, // Ensure the image appears above the slider
                      }}
                    />
                  )}

                </Col> */}



                <Col md={6} style={{ position: 'relative', width: '100%', height: isMobile ? '250px' : '400px' }}>
                  <Carousel style={{
                    width: '100%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: isMobile ? '86%' : '100%',
                    position: 'absolute',
                  }}>
                    {cards.image && (
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={cards.image}
                          alt="First slide"
                          style={{ width: '100%', height: isMobile ? '250px' : '400px', objectFit: 'cover' }} // Fixed width and height
                        />
                      </Carousel.Item>
                    )}


                    {/* <Carousel.Item>
                      <img
                        className="d-block"
                        src={images1[0]}
                        alt="Second slide"
                        style={{
                          width: '100%',
                          height: isMobile ? '250px' : '400px', // Mobile height adjustment
                          objectFit: 'cover',
                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block"
                        src={images1[1]}
                        alt="Third slide"
                        style={{
                          width: '100%',
                          height: isMobile ? '250px' : '400px', // Mobile height adjustment
                          objectFit: 'cover',
                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block"
                        src={images1[2]}
                        alt="Fourth slide"
                        style={{
                          width: '100%',
                          height: isMobile ? '250px' : '400px', // Mobile height adjustment
                          objectFit: 'cover',
                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block"
                        src={images1[3]}
                        alt="Fifth slide"
                        style={{
                          width: '100%',
                          height: isMobile ? '250px' : '400px', // Mobile height adjustment
                          objectFit: 'cover',
                        }}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block"
                        src={images1[4]}
                        alt="Sixth slide"
                        style={{
                          width: '100%',
                          height: isMobile ? '250px' : '400px', // Mobile height adjustment
                          objectFit: 'cover',
                        }}
                      />
                    </Carousel.Item> */}

                    {images1.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={image}
                          alt={`Slide ${index + 1}`} // Start numbering from the second slide
                          style={{
                            width: '100%',
                            height: isMobile ? '250px' : '400px',
                            objectFit: 'cover',
                          }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>

                  {cards.image && (
                    <img
                      variant="top"
                      src={cards.image}
                      alt="Card image"
                      onLoad={handleImageLoad}
                      style={{
                        width: isMobile ? '40%' : '30%', // Adjust size for mobile
                        height: 'auto',
                        borderRadius: isCircle ? '50%' : '10px',
                        position: 'absolute',
                        top: isMobile ? '75%' : '80%', // Adjust positioning for mobile
                        left: '20%',
                        transform: 'translate(-50%, -50%)', // Perfect centering
                        zIndex: 2,
                      }}
                    />
                  )}
                </Col>

              </div>
              <Col xs={12} md={12} className='mt-4 d-flex flex-column align-items-center text-center'>
                {cards.name && (
                  <h3 style={{ marginLeft: '20px', textTransform: 'uppercase', borderBottom: '3px solid skyblue', }}>{cards.name}</h3>
                )}

                {cards.companyName && <p style={{ textTransform: 'uppercase', fontSize: '30px', color: 'white', backgroundColor: 'skyblue', borderRadius: '10px' }}>{cards.companyName}</p>}
                {cards.designation && (
                  <h4 style={{ marginLeft: '20px', textTransform: 'uppercase', borderBottom: '3px solid skyblue', }} >{cards.designation}</h4>
                )}
                {cards.phoneNumber && (
                  <p>
                    <IoMdCall /> {cards.phoneNumber}
                  </p>
                )}
              </Col>


            </Row>


            <Row className="text-center">
              <Col xs={6} sm={4} lg={3} className='p-2'>
                {cards.whatsappChat && (
                  <div>
                    <a href={cards.whatsappChat} style={{ textDecoration: 'none' }}>
                      <button style={{ fontSize: '15px' }} type="button" className="btn btn-outline-primary w-100">
                        <IoLogoWhatsapp /> Whatsapp Chat
                      </button>
                    </a>
                  </div>
                )}
              </Col>
              <Col xs={6} sm={4} lg={3} className='p-2'>
                {cards.location && (
                  <div>
                    <a href={cards.location} target="_blank" style={{ textDecoration: 'none' }}>
                      <button style={{ fontSize: '15px' }} className="btn btn-outline-primary w-100">
                        <ImLocation2 /> Open Location
                      </button>
                    </a>
                  </div>
                )}
              </Col>
              <Col xs={6} sm={4} lg={3} className='p-2'>
                {cards.call && (
                  <div>
                    <a href={`tel:${cards.call}`} style={{ textDecoration: 'none' }}>
                      <button style={{ fontSize: '15px' }} className="btn btn-outline-primary w-100">
                        <MdCall /> Call Now
                      </button>
                    </a>
                  </div>
                )}
              </Col>
              <Col xs={6} sm={4} lg={3} className='p-2'>
                {cards.website && (
                  <div>
                    <a href={cards.website} target="_blank" style={{ textDecoration: 'none' }}>
                      <button style={{ fontSize: '15px' }} className="btn btn-outline-primary w-100">
                        <IoEarth />   WebSite URL
                      </button>
                    </a>
                  </div>
                )}
              </Col>
            </Row>

            {/* <button className="btn btn-outline-primary m-3" onClick={toggleModal}>
        Gallery
      </button> */}


            {/* 
            <button className='btn btn-outline-primary m-3' onClick={handleShowModal}> <IoMdPhotos/>Gallery</button>

            <ModalComponent images={images1} show={showModal} onClose={handleCloseModal} />

            <ModalComponent images={images} />
            <button className="btn btn-outline-primary m-3" onClick={handleOpenModall}>
             <MdVideoLibrary /> Videos
            </button>

            <button
              className='btn btn-outline-primary m-3'
              onClick={() => handleFileOpen(cards.pdf)}
            >
           <BsFileEarmarkPdfFill/>   PDF
            </button>

            <button
              className='btn btn-outline-primary m-3'
              onClick={() => handleFileOpen(cards.ppt)}
            >
            <BsFillFileEarmarkPptFill/>  PPT
            </button> */}


            <Row className="text-center">
              <Col xs={6} sm={4} md={3} className='p-2'>
                <button className='btn btn-outline-primary w-100' onClick={handleShowModal}>
                  <IoMdPhotos /> Gallery
                </button>
                <ModalComponent images={images1} show={showModal} onClose={handleCloseModal} />
              </Col>

              <Col xs={6} sm={4} md={3} className='p-2'>
                <ModalComponent images={images} />
                <button className='btn btn-outline-primary w-100' onClick={handleOpenModall}>
                  <MdVideoLibrary /> Videos
                </button>
              </Col>

              <Col xs={6} sm={4} md={3} className='p-2'>
                {cards.pdf && (
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={() => handleFileOpen(cards.pdf)}
                  >
                    <BsFileEarmarkPdfFill /> PDF
                  </button>
                )}
              </Col>

              <Col xs={6} sm={4} md={3} className='p-2'>
                {cards.ppt && (
                  <button
                    className='btn btn-outline-primary w-100'
                    onClick={() => handleFileOpen(cards.ppt)}
                  >
                    <BsFillFileEarmarkPptFill /> PPT
                  </button>
                )}
              </Col>
            </Row>


            <ModalComponents
              showw={showModall}
              handleClosee={handleCloseModall}
              videoUrl1={cards.videoUrl1}
              videoUrl2={cards.videoUrl2}
            />

            <div className="d-flex flex-column align-items-start mt-2">
              {cards.location && (
                <p className="d-flex align-items-center">
                  <IoMdCall style={{ backgroundColor: "lightblue", color: 'black', fontSize: '30px', borderRadius: '50%', padding: '5px' }} />
                  <span className="ms-2">+91 {cards.phoneNumber}</span>
                </p>
              )}

              {cards.email && (
                <p className="d-flex align-items-center">
                  <BiLogoGmail style={{ backgroundColor: "lightblue", color: 'black', fontSize: '30px', borderRadius: '50%', padding: '5px' }} />
                  <span className="ms-2">{cards.email}</span>
                </p>
              )}

              {cards.address && (
                <p className="d-flex align-items-center">
                  <FaLocationDot style={{ backgroundColor: "lightblue", color: 'black', fontSize: '30px', borderRadius: '50%', padding: '5px' }} />
                  <span className="ms-2">{cards.address}</span>
                </p>
              )}
            </div>

            {/* <Row>
              <Col className='m-4'>
                <ol>
                  <li>{cards.facebookUrl && (
                    <p><a href={cards.facebookUrl} target="_blank" ><FaFacebookF className='icon' /></a></p>
                  )}</li>
                  <li>{cards.linkedinUrl && (
                    <p><a href={cards.linkedinUrl} target="_blank" ><FaLinkedinIn className='icon' /></a></p>
                  )}</li>
                  <li> {cards.instagramUrl && (
                    <p><a href={cards.instagramUrl} target="_blank" ><FaInstagram className='icon' /></a></p>
                  )}</li>
                  <li> {cards.twitterUrl && (
                    <p><a href={cards.twitterUrl} target="_blank" ><FaTwitter className='icon' /></a></p>
                  )}</li>
                  <li> {cards.telegramUrl && (
                    <p><a href={cards.telegramUrl} target="_blank" ><FaTelegramPlane className='icon' /></a></p>
                  )}</li>
                </ol>
              </Col>
              <Col className='p-5 m-4'>
                <button type="button" className="btn btn-outline-success" onClick={downloadVCard}>
                  Download vCard
                </button>
              </Col>
            </Row> */}

            <Row className="text-center">
              <Col xs={12} md={6} className='m-4'>
                <ol className="list-unstyled">
                  {cards.facebookUrl && (
                    <li>
                      <a href={cards.facebookUrl} target="_blank" rel="noopener noreferrer">
                        <FaFacebookF className='icon' />
                      </a>
                    </li>
                  )}
                  {cards.linkedinUrl && (
                    <li>
                      <a href={cards.linkedinUrl} target="_blank" rel="noopener noreferrer">
                        <FaLinkedinIn className='icon' />
                      </a>
                    </li>
                  )}
                  {cards.instagramUrl && (
                    <li>
                      <a href={cards.instagramUrl} target="_blank" rel="noopener noreferrer">
                        <FaInstagram className='icon' />
                      </a>
                    </li>
                  )}
                  {cards.twitterUrl && (
                    <li>
                      <a href={cards.twitterUrl} target="_blank" rel="noopener noreferrer">
                        <FaTwitter className='icon' />
                      </a>
                    </li>
                  )}
                  {cards.telegramUrl && (
                    <li>
                      <a href={cards.telegramUrl} target="_blank" rel="noopener noreferrer">
                        <FaTelegramPlane className='icon' />
                      </a>
                    </li>
                  )}
                </ol>
              </Col>
              <Col xs={12} md={6} className=' m-4 text-center'>
                <button type="button" className="btn btn-outline-success" onClick={downloadVCard}>
                  Download vCard
                </button>
              </Col>
            </Row>


            <Row>
              <Col className='text-start'>



                <QRCodeWithLogo
                  websiteurl={websiteurl}
                  qrCodeSize={qrCodeSize}
                  //  eMedhaLogo={eMedhaLogo} 
                  logoSize={logoSize}
                />




              </Col>
            </Row>
          </div>
        </FormContainer>


      </CardContainer>
      <div>
        {isEditButtonVisible && loginEmail === cards.email && ( // Ensure button is shown only for the owner
          <Button variant="outline-primary" onClick={handleButtonClick}>Edit Card</Button>
        )}

        <EditCardModal
          show={showModal1}
          onClose={() => setShowModal1(false)}
          cardData={cardData}
          onSave={handleSave}
        />
      </div>
    </>
  );
};

export default CardComponent;
