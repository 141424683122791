
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';



const EditCardModal = ({ show, onClose, cardData, onSave }) => {
  // Initialize state with cardData
  const [updatedData, setUpdatedData] = useState(cardData || {});
  const [imageCount, setImageCount] = useState(0);

  console.log(updatedData)

  useEffect(() => {
    if (cardData) {
      setUpdatedData(cardData);
      setImageCount(cardData.imageCount || 0); // Ensure image count is set from cardData
    }
  }, [cardData, show]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };



  const handleImageChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      setUpdatedData(prevData => ({
        ...prevData,
        [`image${index + 1}`]: file,
      }));
    }
  };



  const handleImageCountChange = (e) => {
    const count = Number(e.target.value);
    if (count >= 1 && count <= 10) { // Limit between 1 and 10
      setImageCount(count);
    }
  };

  const [files, setFiles] = useState({
    pdf: null,
    ppt: null,
    logo: null,
    image: null,
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
    image6: null,
    image7: null,
    image8: null,
    image9: null,
    image10: null,
  });



  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // Append form fields
    // Object.keys(updatedData).forEach((key) => {
    //   formData.append(key, updatedData[key]);
    // });
    
    Object.keys(updatedData).forEach((key) => {
      if (key !== 'pdf' && key !== 'ppt') {  // Skip the files
        formData.append(key, updatedData[key]);
      }
    });
    if (updatedData.pdf) {
      formData.append('pdf', updatedData.pdf);
    }
    if (updatedData.ppt) {
      formData.append('ppt', updatedData.ppt);
    }

    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    // Append files
    // Object.keys(files).forEach((key) => {
    //   if (files[key]) {
    //     formData.append(key, files[key]);
    //   }   http://localhost:3009
    // }); https://api.nfcdesignstudio.com/API/v1

    try {
      const response = await axios.post('https://api.nfcdesignstudio.com/API/v1/api/updateCardData', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });


    if (response.status === 200) {
      const { message, formData } = response.data;
      alert(  message);
    }
      console.log(response.data);
     // alert('Form data updated successfully!');
      // Reloads the current page
      window.location.reload();

      onSave(response.data);
      onClose();


    } 
    // catch (error) {
    //   console.error('Error submitting form', error);
    //   alert('Error submitting form. Please try again.');
    // }
    catch (error) {
      if (error.response) {
        // Server-side validation error (e.g., file size exceeded or invalid file type)
        alert(error.response.data.message);  // Show the error message returned by the backend
      } else {
        console.error('Error uploading files:', error);
        alert('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Update the state with the selected file
      setUpdatedData((prevData) => ({
        ...prevData,
        image: file
      }));
    }
  };

  const handleImageChangge = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Update the state with the selected file
      setUpdatedData((prevData) => ({
        ...prevData,
        logo: file
      }));
    }
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      setUpdatedData(prevData => ({
        ...prevData,
        [type]: file,
      }));
    }
  };


  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Populate fields with cardData */}
          <Form.Group>
            <Form.Label>Company Background Image:</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageChangge}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Company Logo:</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"

              onChange={(e) => handleImageChange1(e)}
            />
          </Form.Group>
          <Form.Group controlId="companyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              value={updatedData.companyName || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={updatedData.name || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="designation">
            <Form.Label>Designation</Form.Label>
            <Form.Control
              type="text"
              name="designation"
              value={updatedData.designation || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              name="phoneNumber"
              value={updatedData.phoneNumber || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={updatedData.email || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              as="textarea"
              name="address"
              value={updatedData.address || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="whatsappChat">
            <Form.Label>Whatsapp Chat Link</Form.Label>
            <Form.Control
              type="text"
              name="whatsappChat"
              value={updatedData.whatsappChat || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="location">
            <Form.Label>Location Link</Form.Label>
            <Form.Control
              type="text"
              name="location"
              value={updatedData.location || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="call">
            <Form.Label>Call Link</Form.Label>
            <Form.Control
              type="text"
              name="call"
              value={updatedData.call || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="website">
            <Form.Label>Website URL</Form.Label>
            <Form.Control
              type="url"
              name="website"
              value={updatedData.website || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="pdf">
            <Form.Label>PDF</Form.Label>
            <Form.Control
              type="file"
              accept=".pdf"
              name="pdf"
              onChange={(e) => handleFileChange(e, 'pdf')}
            />
          </Form.Group>
          <Form.Group controlId="ppt">
            <Form.Label>PPT</Form.Label>
            <Form.Control
              type="file"
              accept=".ppt,.pptx"
              name="ppt"
              onChange={(e) => handleFileChange(e, 'ppt')}
            />
          </Form.Group>
          <Form.Group controlId="videoUrl1">
            <Form.Label>Video URL 1</Form.Label>
            <Form.Control
              type="url"
              name="videoUrl1"
              value={updatedData.videoUrl1 || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="videoUrl2">
            <Form.Label>Video URL 2</Form.Label>
            <Form.Control
              type="url"
              name="videoUrl2"
              value={updatedData.videoUrl2 || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="facebookUrl">
            <Form.Label>Facebook URL</Form.Label>
            <Form.Control
              type="url"
              name="facebookUrl"
              value={updatedData.facebookUrl || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="linkedinUrl">
            <Form.Label>LinkedIn URL</Form.Label>
            <Form.Control
              type="url"
              name="linkedinUrl"
              value={updatedData.linkedinUrl || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="instagramUrl">
            <Form.Label>Instagram URL</Form.Label>
            <Form.Control
              type="url"
              name="instagramUrl"
              value={updatedData.instagramUrl || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="twitterUrl">
            <Form.Label>Twitter URL</Form.Label>
            <Form.Control
              type="url"
              name="twitterUrl"
              value={updatedData.twitterUrl || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="telegramUrl">
            <Form.Label>Telegram URL</Form.Label>
            <Form.Control
              type="url"
              name="telegramUrl"
              value={updatedData.telegramUrl || ''}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="imageCount">
            <Form.Label>Number of Images</Form.Label>
            <Form.Control
              type="number"
              value={imageCount || 0}
              onChange={handleImageCountChange}
              placeholder="Enter a number of image files (Max-10)"
              min="0"
              max="10"
            />
          </Form.Group>
          {Array.from({ length: imageCount }, (_, index) => (
            <Form.Group key={index} controlId={`image${index + 1}`}>
              <Form.Label>Image {index + 1}</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(index, e)}
              />
            </Form.Group>
          ))}
          <Button variant="primary" type="submit">
            Apply Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};


export default EditCardModal;